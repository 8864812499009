import DynamicLink from 'components/dynamiclink/DynamicLink'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { render } from 'storyblok-rich-text-react-renderer'
import { StoryblokContent } from 'types/StoryblokContent'

import { storyblokEditable } from '@storyblok/react'

import RightArrow from '../../icons/RightArrow'

import styles from './TopBanner.module.scss'

type PropTypes = {
  blok?: StoryblokContent
}

const TopBanner: FC<PropTypes> = ({ blok }) => {
  if (!blok || blok.hide) return null

  const router = useRouter()
  if (router.asPath !== '/') return null
  return (
    <div {...storyblokEditable(blok)}>
      <DynamicLink linkObject={blok.link}>
        <div className={styles.container} style={{ color: blok.font_color.color, background: blok.background_color.color }}>
          {blok.percentage && <p>blok.percentage</p>}
          <div className={styles.richtextContainer}>
            {render(blok.text)}
            {blok.arrow && <RightArrow width={36} height={36} />}
          </div>
        </div>
      </DynamicLink>
    </div>
  )
}

export default TopBanner
