import { Box } from 'atomic'
import Check from 'components/icons/Check'
import React, { FC } from 'react'
import * as css from './LanguageCheck.css'

type LanguageCheckProps = {
  active: boolean
}

const LanguageCheck: FC<LanguageCheckProps> = ({ active }) => {
  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      borderRadius={'full'}
      backgroundColor={active ? 'mossa2' : 'transparent'}
      className={css.container[active ? 'active' : 'notActive']}
    >
      {' '}
      {active && <Check />}{' '}
    </Box>
  )
}

export default LanguageCheck
