import Image from 'next/image'
import { FC } from 'react'

import LocalizedObject from '../../types/LocalizedObject'
import { Link } from 'components/link/Link'
import { trackOutboundLink } from 'lib/analytics-utils'
import useAppLinks from 'hooks/useAppLinks'

const icons: LocalizedObject = {
  'de-de': 'de.svg',
  'da-dk': 'da.svg',
  'en-gb': 'en.svg',
  'en-au': 'en.svg',
  'en-us': 'en.svg',
  'en-ca': 'en.svg',
  'es-es': 'es.svg',
  'fr-fr': 'fr.svg',
  'it-it': 'it.svg',
  'nl-nl': 'nl.svg',
  'nb-no': 'no.svg',
  'sv-se': 'sv.svg',
}

type PropTypes = {
  locale: string
}

const GooglePlay: FC<PropTypes> = ({ locale }) => {
  const { googlePlayUrl } = useAppLinks()
  const localizedIcon = `/icons/googleplay/${icons[locale]}`
  return (
    <Link onClick={() => trackOutboundLink(googlePlayUrl)} href={googlePlayUrl} target="_blank" aria-label="Link to Google Play">
      <Image src={localizedIcon} width={100.76} height={26} alt="Google Play badge" />
    </Link>
  )
}

export default GooglePlay
