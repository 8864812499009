import DynamicLink from 'components/dynamiclink/DynamicLink'
import ExternalLink from 'components/externallink/ExternalLink'
import InternalLink from 'components/internallink/InternalLink'
import useAppLinks from 'hooks/useAppLinks'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { withTheme } from 'styled-components'
import { StoryblokContent } from 'types/StoryblokContent'
import { StoryblokLink } from 'types/StoryblokLink'
import { urlFromStory } from 'utils/url-from-story'

import { Button, TextButton } from '@onceuponapp/ui'
import { storyblokEditable } from '@storyblok/react'

import { useModalState } from '../../../context/ModalStateContext'
import { Theme } from '../../../styles/Theme'
import { filteredNavHeaders, filteredNavItemsForNavHeader } from '../../../utils/navigation-items'
import ActionDiv from '../../actiondiv/ActionDiv'
import DownArrow from '../../icons/DownArrow'
import OUTextLogo from '../../icons/OU_TextLogo'
import UpArrow from '../../icons/UpArrow'
import { defaultLocale } from '../../languagepicker/LanguageHelper'
import MenuButton from '../../menubutton/MenuButton'
import { DisableGlobalScroll } from '../../styled/Overlay'

import styles from './Header.module.scss'
import { useSafeTranslation } from 'hooks/useSafeTranslation'

type PropTypes = {
  blok?: StoryblokContent
  theme: Theme
  topBannerIsShowing?: boolean
}

const Header: FC<PropTypes> = ({ blok, theme, topBannerIsShowing }) => {
  const router = useRouter()
  const { t } = useSafeTranslation()
  const { setShowGetAppModal } = useModalState()
  const navHeaders = filteredNavHeaders(blok.links)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [menuIsExpanded, setMenuIsExpanded] = useState(null)
  const [expandedNavHeaders, setExpandedNavHeaders] = useState(
    blok.links.reduce((accumulator: StoryblokContent, currentValue: StoryblokContent) => {
      const key = currentValue._uid
      accumulator[key] = false
      return accumulator
    }, {})
  )
  const { activeOsUrl } = useAppLinks()

  useEffect(() => {
    const isNotOnRootPath = router.asPath !== '/'
    if (!topBannerIsShowing || isNotOnRootPath) {
      const stickyHeader = document.querySelector('header')
      const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('isSticky', e.intersectionRatio < 1), { threshold: [1] })
      observer.observe(stickyHeader)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  const handleMediaQueryChange = (isDesktop: boolean): void => {
    if (isDesktop) {
      setMobileMenuOpen(false)
    }
  }

  const handleRouteChange = (): void => {
    setMobileMenuOpen(false)
  }

  useMediaQuery({ minWidth: theme.screenSizes.large }, undefined, handleMediaQueryChange)

  const firstNavItemLink = (navHeader: StoryblokContent): StoryblokLink | null => {
    return navHeader.items.length > 0 ? navHeader.items[0].link : null
  }

  const navHeaderIsExpandable = (navHeader: StoryblokContent): boolean => {
    return navHeader.items.length > 1
  }

  const navHeaderIsOpen = (navHeader: StoryblokContent): boolean => {
    return expandedNavHeaders[navHeader._uid]
  }

  const toggleNavheader = (navHeader: StoryblokContent): void => {
    setExpandedNavHeaders((prevState: StoryblokContent) => ({
      ...prevState,
      [navHeader._uid]: !prevState[navHeader._uid],
    }))
  }

  const headerIsActive = (navHeader: StoryblokContent): boolean => {
    const activeRouteWithLocale = router.locale === defaultLocale ? router.asPath : `/${router.locale}${router.asPath}`
    if (navHeader.items && navHeader.items.length > 0) {
      // special hack of Stories as the menu is in Storyblok and the blog in Builder
      if (navHeader.items[0].name === 'Stories') {
        return activeRouteWithLocale.includes('/stories')
      }

      const navHeaderIncludesActiveRoute = navHeader.items.find((navItem: StoryblokContent) =>
        activeRouteWithLocale.startsWith(urlFromStory(navItem.link))
      )
      return navHeaderIncludesActiveRoute !== undefined
    }
    return false
  }

  const navItemIsActive = (navItem: StoryblokContent): boolean => {
    const activeRouteWithLocale = router.locale === defaultLocale ? router.asPath : `/${router.locale}${router.asPath}`
    return urlFromStory(navItem.link) === activeRouteWithLocale
  }

  const renderArrow = (navHeader: StoryblokContent): JSX.Element => {
    return navHeaderIsOpen(navHeader) ? <UpArrow dark /> : <DownArrow dark />
  }

  const renderNavItems = (navHeader: StoryblokContent): JSX.Element => {
    const navItems = filteredNavItemsForNavHeader(navHeader)
    return navItems.map((navItem: StoryblokContent) => (
      <li key={navItem._uid} className={styles.navLinkContainer} style={{ display: 'flex' }}>
        <DynamicLink linkObject={navItem.link}>
          <span
            className={styles.navLink}
            onClick={() => (mobileMenuOpen ? setMobileMenuOpen(false) : setMenuIsExpanded(null))}
            role="button"
            tabIndex={0}
            onKeyDown={null}
            style={navItemIsActive(navItem) ? { fontWeight: 400 } : { fontWeight: 300 }}
          >
            {navItem.name}
          </span>
        </DynamicLink>
      </li>
    ))
  }

  const renderDesktopMenu = (): JSX.Element => {
    return (
      <nav>
        <ul className={`${styles.desktopNav} largerThanLarge`}>
          {navHeaders.map((navHeader: StoryblokContent) => (
            <li
              onMouseEnter={() => setMenuIsExpanded(navHeader._uid)}
              onMouseLeave={() => setMenuIsExpanded(null)}
              key={navHeader._uid}
              className={navHeaderIsExpandable(navHeader) ? styles.expandable : null}
            >
              <div className={navHeader.campaign ? `${styles.navHeaderContainer} ${styles.campaign}` : styles.navHeaderContainer}>
                {navHeader.title === 'Stories' && (
                  <InternalLink href="/stories" anchor={''}>
                    <span className={headerIsActive(navHeader) ? `${styles.navHeader} ${styles.active}` : styles.navHeader} title={navHeader.title}>
                      {navHeader.title}
                    </span>
                  </InternalLink>
                )}
                {navHeader.title !== 'Stories' && (
                  <DynamicLink linkObject={firstNavItemLink(navHeader)}>
                    <span className={headerIsActive(navHeader) ? `${styles.navHeader} ${styles.active}` : styles.navHeader} title={navHeader.title}>
                      {navHeader.title}
                    </span>
                  </DynamicLink>
                )}
              </div>
              <ul className={navHeader._uid === menuIsExpanded ? styles.expanded : null}>{renderNavItems(navHeader)}</ul>
            </li>
          ))}
        </ul>
      </nav>
    )
  }

  const renderMobileMenu = (): JSX.Element => {
    return (
      <nav>
        <ul className={mobileMenuOpen ? `${styles.mobileNav} ${styles.open} smallerThanLarge` : styles.mobileNav}>
          {navHeaders.map((navHeader: StoryblokContent) => (
            <li
              key={navHeader._uid}
              className={
                navHeaderIsExpandable(navHeader) ? (navHeaderIsOpen(navHeader) ? `${styles.open} ${styles.expandable}` : styles.expandable) : null
              }
            >
              {navHeaderIsExpandable(navHeader) ? (
                <ActionDiv className={styles.navHeaderContainer} onClick={() => toggleNavheader(navHeader)} ariaLabel="expand navigation item">
                  <p className={styles.navHeader}>{navHeader.title}</p>
                  {renderArrow(navHeader)}
                </ActionDiv>
              ) : navHeader.title === 'Stories' ? (
                <div className={styles.navHeaderContainer}>
                  <InternalLink href="/stories" anchor={''}>
                    <span className={styles.navHeader}>{navHeader.title}</span>
                  </InternalLink>
                </div>
              ) : (
                <div className={navHeader.campaign ? `${styles.navHeaderContainer} ${styles.campaign}` : styles.navHeaderContainer}>
                  <DynamicLink linkObject={firstNavItemLink(navHeader)}>
                    <span style={{ marginBottom: 16 }} className={styles.navHeader}>
                      {navHeader.title}
                    </span>
                  </DynamicLink>
                </div>
              )}
              <ul>{renderNavItems(navHeader)}</ul>
            </li>
          ))}
          <li className={styles.listItemForLogin}>
            <ExternalLink href="https://app.onceupon.photo/">
              <Button theme="secondary">{t('Authentication_signin_button')}</Button>
            </ExternalLink>
          </li>
        </ul>
      </nav>
    )
  }

  return (
    <>
      <DisableGlobalScroll show={mobileMenuOpen} />
      <header {...storyblokEditable(blok)}>
        <div className={menuIsExpanded ? `${styles.container} ${styles.overlay}` : styles.container}>
          <div className={styles.leftContainer}>
            <div className={styles.logoContainer}>
              <InternalLink style={{ display: 'flex' }} href="/">
                <OUTextLogo aria-label="Link to start" width={120} height={39} />
              </InternalLink>
            </div>
            {renderDesktopMenu()}
            {renderMobileMenu()}
          </div>
          <div className={styles.rightContainer}>
            <div className={`${styles.row} smallerThanSmall`}>
              <ExternalLink href={activeOsUrl}>
                <Button theme="cta">{blok.get_app_button_mobile}</Button>
              </ExternalLink>
            </div>
            <div className={`${styles.row} largerThanLarge`}>
              <TextButton className={styles.textButton} icon="onceuponlogo" onClick={() => setShowGetAppModal(true)}>
                {blok.get_app_button_mobile}
              </TextButton>
              <ExternalLink href={`https://app.onceupon.photo/authentication/signin/?lang=${router.locale}`}>
                <Button theme="secondary">{t('Authentication_signin_button')}</Button>
              </ExternalLink>
              <ExternalLink href={`https://app.onceupon.photo/authentication/signup/?lang=${router.locale}`}>
                <Button theme="cta">{t('authentication_signup_btn')}</Button>
              </ExternalLink>
            </div>
            <MenuButton menuOpen={mobileMenuOpen} setmenuOpen={() => setMobileMenuOpen(!mobileMenuOpen)} />
          </div>
        </div>
      </header>
    </>
  )
}

export default withTheme(Header)
