/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { useRouter } from 'next/router'
import { StoryblokContent } from 'types/StoryblokContent'

export const filteredNavHeaders = (links: StoryblokContent): StoryblokContent => {
  const filteredNavHeaders = links.filter((navHeader: StoryblokContent) => !navHeader.hide && !currentLocaleIsHidden(navHeader.hide_for_language))

  filteredNavHeaders.forEach((navHeader) => {
    if (navHeader.items?.length > 0) {
      navHeader.items = filteredNavItemsForNavHeader(navHeader)
    }
  })

  return filteredNavHeaders
}

export const filteredNavItemsForNavFooter = (links: StoryblokContent): StoryblokContent => {
  const navHeaders = filteredNavHeaders(links)
  const allNavItems = navHeaders.map((link: StoryblokContent) => link.items.map((navItem: StoryblokContent) => ({ ...navItem }))).flat()

  const filteredNavItems = allNavItems.filter((navItem: StoryblokContent) => {
    return !navItem.link?.anchor || navItem.link?.anchor === ''
  })

  return filterNavItems(filteredNavItems)
}

export const filteredNavItemsForNavHeader = (navHeader: StoryblokContent): StoryblokContent => {
  return filterNavItems(navHeader.items)
}

function filterNavItems(navItems: StoryblokContent): StoryblokContent {
  return navItems.filter((navItem: StoryblokContent) => !navItem.hide && navItem.name !== '')
}

const currentLocaleIsHidden = (hiddenLocales: string[]): boolean => {
  const router = useRouter()
  return !!hiddenLocales?.find((locale: string) => locale === router.locale)
}
