import React, { FC } from 'react'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { getCampaignParameters } from 'utils/get-campaign-parameters'

const SmartBannerScript: FC = () => {
  const router = useRouter()
  // get and format parameters for Smartbanners
  const campaignParameters = getCampaignParameters(router.query)
  const parameterStrings = []
  if (campaignParameters) {
    Object.entries(campaignParameters).forEach(([key, value]) => {
      if (value?.length > 0) parameterStrings.push(key + ': "' + value + '"')
    })
  }

  return (
    <Script
      id="smartbanner"
      strategy="beforeInteractive"
      dangerouslySetInnerHTML={{
        __html: `!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","banners",{banners: {key: "ce39d2c0-9cdc-4496-9a47-2fe1ddf70d7e"}});
AF('banners', 'showBanner', { additionalParams: { ${parameterStrings.toString()} }})`,
      }}
    />
  )
}

export default SmartBannerScript
