import { Dispatch, FC, SetStateAction } from 'react'

import ActionDiv from '../actiondiv/ActionDiv'

import styles from './MenuButton.module.scss'

type PropTypes = {
  menuOpen: boolean
  setmenuOpen: Dispatch<SetStateAction<boolean>>
}

const MenuButton: FC<PropTypes> = ({ menuOpen, setmenuOpen }) => {
  return (
    <ActionDiv
      className={`${styles.container} ${menuOpen ? styles.active : undefined}`}
      onClick={() => setmenuOpen(!menuOpen)}
      ariaLabel="Menu button"
    >
      <div className={`${styles.menuButton} ${menuOpen ? styles.active : undefined}`}>
        <span className={styles.topLine} />
        <span className={styles.centerLine} />
        <span className={styles.bottomLine} />
      </div>
    </ActionDiv>
  )
}

export default MenuButton
