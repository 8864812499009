import { FC } from 'react'

const LanguageIcon: FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 4C2.44772 4 2 4.44772 2 5C2 5.55228 2.44772 6 3 6V4ZM11 6C11.5523 6 12 5.55228 12 5C12 4.44772 11.5523 4 11 4V6ZM8 3C8 2.44772 7.55228 2 7 2C6.44772 2 6 2.44772 6 3H8ZM2.68377 11.0513C2.15983 11.226 1.87667 11.7923 2.05132 12.3162C2.22596 12.8402 2.79228 13.1233 3.31623 12.9487L2.68377 11.0513ZM8.70711 9.29289C8.31658 8.90237 7.68342 8.90237 7.29289 9.29289C6.90237 9.68342 6.90237 10.3166 7.29289 10.7071L8.70711 9.29289ZM9.29289 12.7071C9.68342 13.0976 10.3166 13.0976 10.7071 12.7071C11.0976 12.3166 11.0976 11.6834 10.7071 11.2929L9.29289 12.7071ZM12.0862 20.5939C11.8619 21.0985 12.0892 21.6895 12.5939 21.9138C13.0985 22.1381 13.6895 21.9108 13.9138 21.4061L12.0862 20.5939ZM17 12L17.9138 11.5939C17.7533 11.2327 17.3952 11 17 11C16.6048 11 16.2467 11.2327 16.0862 11.5939L17 12ZM20.0862 21.4061C20.3105 21.9108 20.9015 22.1381 21.4061 21.9138C21.9108 21.6895 22.1381 21.0985 21.9138 20.5939L20.0862 21.4061ZM14 18C13.4477 18 13 18.4477 13 19C13 19.5523 13.4477 20 14 20V18ZM20 20C20.5523 20 21 19.5523 21 19C21 18.4477 20.5523 18 20 18V20ZM3 6H7V4H3V6ZM7 6H11V4H7V6ZM6 3V5H8V3H6ZM8 5C8 6.17179 7.40526 7.44375 6.38171 8.58104C5.36484 9.71089 4.00913 10.6095 2.68377 11.0513L3.31623 12.9487C4.99087 12.3905 6.63516 11.2891 7.86829 9.91896C9.09474 8.55625 10 6.82821 10 5H8ZM7.29289 10.7071L9.29289 12.7071L10.7071 11.2929L8.70711 9.29289L7.29289 10.7071ZM13.9138 21.4061L17.9138 12.4061L16.0862 11.5939L12.0862 20.5939L13.9138 21.4061ZM16.0862 12.4061L20.0862 21.4061L21.9138 20.5939L17.9138 11.5939L16.0862 12.4061ZM14 20H20V18H14V20Z"
      fill="#222222"
    />
  </svg>
)

export default LanguageIcon
